const sortModes = [
  {
    name: "similarityscore",
    display: "Most Similiar",
    icon: "code-compare",
    arrowIcon: "arrow-up",
    type: "Similarity",
  },
  {
    name: "distancetocenter",
    display: "Nearest",
    icon: "drafting-compass",
    arrowIcon: "arrow-down",
    type: "Dist.",
  },
  {
    name: "bestdeal",
    display: "Best Deal",
    icon: "money-bill-alt",
    arrowIcon: "arrow-up",
    type: "Value",
  },
  {
    name: "cheapest",
    display: "Cheapest Price",
    icon: "pound-sign",
    arrowIcon: "arrow-down",
    type: "Price",
  },
  {
    name: "expensive",
    display: "Highest Price",
    icon: "pound-sign",
    arrowIcon: "arrow-up",
    type: "Price",
  },
  {
    name: "lowppsf",
    display: "Cheapest £/sqft",
    icon: "percent",
    arrowIcon: "arrow-down",
    type: "£/sqft",
  },
  {
    name: "highppsf",
    display: "Highest £/sqft ",
    icon: "percent",
    arrowIcon: "arrow-up",
    type: "£/sqft",
  },
  {
    name: "newest",
    display: "Newest",
    icon: "calendar-alt",
    arrowIcon: "arrow-up",
    type: "Date",
  },
  {
    name: "oldest",
    display: "Oldest",
    icon: "calendar-alt",
    arrowIcon: "arrow-down",
    type: "Date",
  },

  {
    name: "biggest",
    display: "Biggest Size",
    icon: "ruler",
    arrowIcon: "arrow-up",
    type: "Size",
  },
  {
    name: "smallest",
    display: "Smallest Size",
    icon: "ruler",
    arrowIcon: "arrow-down",
    type: "Size",
  },
  {
    name: "mostbeds",
    display: "Most Beds",
    icon: "bed",
    arrowIcon: "arrow-up",
    type: "Beds",
  },
  {
    name: "leastbeds",
    display: "Least Beds",
    icon: "bed",
    arrowIcon: "arrow-down",
    type: "Beds",
  },
];

export default sortModes;
