import blockedDomains from "./blocked_domains_13nov24.json";

const customBlockList = [
  "yoli.cam",
  "couldmail.com",
  "qmail.my",
  "vigoneo.com",
  "azuretechtalk.net",
  "flashpost.net",
];

export const isValidDomain = (emailDomain) => {
  if (
    blockedDomains.includes(emailDomain) ||
    customBlockList.includes(emailDomain)
  ) {
    return false;
  } else {
    return true;
  }
};
