/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setComparableProperty = /* GraphQL */ `
  mutation SetComparableProperty(
    $searchID: String!
    $propertyID: String!
    $isComparable: Boolean
  ) {
    setComparableProperty(
      searchID: $searchID
      propertyID: $propertyID
      isComparable: $isComparable
    )
  }
`;
export const setFavouriteSearch = /* GraphQL */ `
  mutation SetFavouriteSearch($searchID: String!, $isFavourite: Boolean!) {
    setFavouriteSearch(searchID: $searchID, isFavourite: $isFavourite)
  }
`;
export const setSearchSortOrder = /* GraphQL */ `
  mutation SetSearchSortOrder($searchID: String!, $searchSortOrder: String!) {
    setSearchSortOrder(searchID: $searchID, searchSortOrder: $searchSortOrder)
  }
`;
export const setShowTodayPrice = /* GraphQL */ `
  mutation SetShowTodayPrice($searchID: String!, $showTodayPrice: Boolean!) {
    setShowTodayPrice(searchID: $searchID, showTodayPrice: $showTodayPrice)
  }
`;
export const setSearchCustomName = /* GraphQL */ `
  mutation SetSearchCustomName($searchID: String!, $searchCustomName: String!) {
    setSearchCustomName(
      searchID: $searchID
      searchCustomName: $searchCustomName
    )
  }
`;
export const setValuationPrice = /* GraphQL */ `
  mutation SetValuationPrice(
    $valuationId: String!
    $newPrice: Float!
    $newPriceSource: String
  ) {
    setValuationPrice(
      valuationId: $valuationId
      newPrice: $newPrice
      newPriceSource: $newPriceSource
    ) {
      valuationId
      valuationName
      valuationDate
      valuationPropertyId
      valuationPrice
      valuationPriceSource
      soldSearchId
      forsaleSearchId
      rentalSearchId
      planningSearchId
      newBuildSearchId
      __typename
    }
  }
`;
export const runValuationSearch = /* GraphQL */ `
  mutation RunValuationSearch(
    $valuationId: String!
    $valuationName: String
    $searchType: String!
    $lat: Float!
    $lng: Float!
    $propertyType: String!
    $beds: Int
    $price: Float
  ) {
    runValuationSearch(
      valuationId: $valuationId
      valuationName: $valuationName
      searchType: $searchType
      lat: $lat
      lng: $lng
      propertyType: $propertyType
      beds: $beds
      price: $price
    ) {
      valuationId
      valuationName
      valuationDate
      valuationPropertyId
      valuationPrice
      valuationPriceSource
      soldSearchId
      forsaleSearchId
      rentalSearchId
      planningSearchId
      newBuildSearchId
      __typename
    }
  }
`;
export const runValuationSearches = /* GraphQL */ `
  mutation RunValuationSearches(
    $valuationId: String!
    $valuationName: String
    $lat: Float!
    $lng: Float!
    $propertyType: String!
    $beds: Int
    $price: Float
  ) {
    runValuationSearches(
      valuationId: $valuationId
      valuationName: $valuationName
      lat: $lat
      lng: $lng
      propertyType: $propertyType
      beds: $beds
      price: $price
    )
  }
`;
export const expandValuationSearch = /* GraphQL */ `
  mutation ExpandValuationSearch(
    $valuationId: String!
    $oldSearchId: String!
    $oldSearchLocationName: String!
    $searchType: String!
    $newSearchSpec: SearchInput!
  ) {
    expandValuationSearch(
      valuationId: $valuationId
      oldSearchId: $oldSearchId
      oldSearchLocationName: $oldSearchLocationName
      searchType: $searchType
      newSearchSpec: $newSearchSpec
    ) {
      valuationId
      valuationName
      valuationDate
      valuationPropertyId
      valuationPrice
      valuationPriceSource
      soldSearchId
      forsaleSearchId
      rentalSearchId
      planningSearchId
      newBuildSearchId
      __typename
    }
  }
`;
export const refineLandregPrice = /* GraphQL */ `
  mutation RefineLandregPrice(
    $valuationId: String!
    $ValuationInputSpec: ValuationInputSpec
  ) {
    refineLandregPrice(
      valuationId: $valuationId
      ValuationInputSpec: $ValuationInputSpec
    )
  }
`;
export const createValuation = /* GraphQL */ `
  mutation CreateValuation(
    $valuationMode: String!
    $valuationInputSpec: ValuationInputSpec!
    $referringDomainName: String
  ) {
    createValuation(
      valuationMode: $valuationMode
      valuationInputSpec: $valuationInputSpec
      referringDomainName: $referringDomainName
    )
  }
`;
export const search = /* GraphQL */ `
  mutation Search(
    $searchName: String!
    $searchSource: String!
    $searchSpec: SearchInput!
    $oldSearchId: String
    $searchLocationName: String
  ) {
    search(
      searchName: $searchName
      searchSource: $searchSource
      searchSpec: $searchSpec
      oldSearchId: $oldSearchId
      searchLocationName: $searchLocationName
    )
  }
`;
export const starProperty = /* GraphQL */ `
  mutation StarProperty($propertyID: String!) {
    starProperty(propertyID: $propertyID)
  }
`;
export const hideProperty = /* GraphQL */ `
  mutation HideProperty($propertyID: String!) {
    hideProperty(propertyID: $propertyID)
  }
`;
export const unstarProperty = /* GraphQL */ `
  mutation UnstarProperty($propertyID: String!) {
    unstarProperty(propertyID: $propertyID)
  }
`;
export const unhideProperty = /* GraphQL */ `
  mutation UnhideProperty($propertyID: String!) {
    unhideProperty(propertyID: $propertyID)
  }
`;
export const addManualSize = /* GraphQL */ `
  mutation AddManualSize($propertyID: String!, $sqft: Float!) {
    addManualSize(propertyID: $propertyID, sqft: $sqft)
  }
`;
