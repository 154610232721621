import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Rating from "@material-ui/lab/Rating";

import {
  capitaliseFirstLetter,
  formatDateDDMMMYYY,
  formatDateDDMMYY,
  formatMoneyFull,
} from "services/formatting";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "pages/styleConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import useUnits from "data/hooks/useUnits";

const formatSource = (source) => {
  return source === "ppsfavg"
    ? "Average"
    : source === "ppsfavghigh"
    ? "Above Average"
    : source === "ppsfhigh"
    ? "Best"
    : source === "ppsflowavg"
    ? "Below Average"
    : source === "ppsflow"
    ? "Worst"
    : source;
};

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    maxWidth: 1280,
    padding: "0 20px",
    "@media(max-width: 860px)": { padding: "0 3px" },
  },
  body: {},
  smallCell: {
    // backgroundColor: "red",
    padding: "8px",
    fontSize: "15px",
    minWidth: "50px",
    transition: (width) => `all 0.2s ease-in-out`,
    "@media(max-width: 860px)": {
      minWidth: "25px",
      fontSize: "12px",
      padding: "4px",
    },
    textAlign: "center",
  },
  cell: {
    // backgroundColor: "red",
    padding: "8px",
    fontSize: "15px",
    minWidth: "130px",
    transition: (width) => `all 0.2s ease-in-out`,
    "@media(max-width: 860px)": {
      minWidth: "40px",
      fontSize: "12px",
      padding: "4px",
    },
    textAlign: "center",
  },
  addressCell: {
    padding: "8px",
    "@media(max-width: 860px)": {
      padding: "4px",
    },
    width: "100%",
    whiteSpace: "normal",
    wordWrap: "break-word",
    minWidth: "100px",
  },
});

// const SearchFavouriteStar = ({ item, setFavouriteHandler }) => {
//   return (
//     <Rating
//       name={`favourite-star-${item.searchID}`}
//       value={item.isFavourite ? 1 : 0}
//       onClick={(event) => {
//         setFavouriteHandler(item.searchID, !item.isFavourite);
//         event.stopPropagation();
//         event.preventDefault();
//       }}
//       max={1}
//       size="large"
//     />
//   );
// };

const ReportHistoryTable = ({ searchHistory, loadURL }) => {
  const classes = useStyles();
  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.narrow}px)`);
  const isMediumWidth = useMediaQuery(`(max-width:${deviceSizes.n780}px)`);
  const { displayUnits, units } = useUnits();

  const [renderedReportHistory, setRenderedReportHistory] =
    useState(searchHistory);

  // const setFavouriteHandler = (searchID, isFavourite) => {
  //   const updatedSearch = {
  //     ...renderedReportHistory.find((item) => item.searchID === searchID),
  //     isFavourite,
  //   };
  //   const newArray = renderedReportHistory.map((item) =>
  //     item.searchID === searchID ? updatedSearch : item
  //   );

  //   setRenderedReportHistory(newArray);
  //   setFavouriteSearch(searchID, isFavourite);
  // };

  const tableDefinitions = {
    valuation: [
      "Report Location",
      ...(!isNarrow
        ? [
            !isMediumWidth ? (
              `Type`
            ) : (
              <FontAwesomeIcon icon={regular("house")} />
            ),
          ]
        : []),
      ...(!isNarrow
        ? [!isMediumWidth ? `Beds` : <FontAwesomeIcon icon={regular("bed")} />]
        : []),
      !isMediumWidth ? (
        displayUnits === "ppsm" ? (
          "Sqm"
        ) : (
          "Sqft"
        )
      ) : (
        <FontAwesomeIcon icon={regular("ruler")} />
      ),
      !isMediumWidth ? (
        `Price Estimate`
      ) : (
        <FontAwesomeIcon icon={regular("coins")} />
      ),
      !isMediumWidth ? (
        `Condition`
      ) : (
        <FontAwesomeIcon icon={regular("temperature-list")} />
      ),
      !isMediumWidth ? `Date Run` : <FontAwesomeIcon icon={regular("clock")} />,
    ],
  };

  const LWTableHeader = () => {
    return (
      <>
        <TableRow>
          {/* <TableCell style={{ fontWeight: "bold" }}></TableCell> */}
          {/* <TableCell>
            <FontAwesomeIcon icon={regular("list")} />
          </TableCell> */}
          <TableCell
            style={{ fontWeight: "bold" }}
            className={classes.addressCell}
            colSpan={1}
          >
            {tableDefinitions.valuation[0]}
          </TableCell>
          {tableDefinitions.valuation.map((item, index) => {
            if (index > 0) {
              return (
                <TableCell
                  key={index}
                  style={{ fontWeight: "bold" }}
                  className={
                    ["Type", "Beds", "Sqft", "Sqm"].includes(item)
                      ? classes.smallCell
                      : classes.cell
                  }
                  colSpan={1}
                  align="right"
                >
                  <div className="flex-center-center">{item}</div>
                </TableCell>
              );
            } else return null;
          })}
        </TableRow>
      </>
    );
  };

  // const MetadataCell = ({ classes, data }) => (
  //   <TableCell className={classes.cell} align="right">
  //     {data}
  //   </TableCell>
  // );

  const Row = ({ item }) => {
    // const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow
          hover
          key={item.searchID}
          onClick={() => loadURL(item.valuationId)}
        >
          {/* <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
          {/* <TableCell component="th" scope="row">
            <SearchFavouriteStar
              item={item}
              setFavouriteHandler={setFavouriteHandler}
            />
          </TableCell> */}
          <TableCell
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
            className={classes.addressCell}
            component="th"
            scope="row"
          >
            {item.valuationName}
            {/* <br />
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>Search Configuration goes here</ListItemIcon>
                </ListItem>
              </List>
            </Collapse> */}
          </TableCell>{" "}
          {!isNarrow && (
            <TableCell className={classes.smallCell} align="right">
              {item.valuationPropertyType ? (
                capitaliseFirstLetter(item.valuationPropertyType)
              ) : (
                <span style={{ color: "#999" }}>-</span>
              )}
            </TableCell>
          )}
          {!isNarrow && (
            <TableCell className={classes.smallCell} align="right">
              {item.valuationPropertyBeds ? (
                item.valuationPropertyBeds
              ) : (
                <span style={{ color: "#999" }}>-</span>
              )}
            </TableCell>
          )}
          <TableCell className={classes.smallCell} align="right">
            {item.valuationPropertySqft ? (
              displayUnits === "ppsm" ? (
                Math.round(item.valuationPropertySqft / 10.7639)
              ) : (
                item.valuationPropertySqft
              )
            ) : (
              <span style={{ color: "#999" }}>-</span>
            )}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {item.valuationPrice > 0 ? (
              "£" + formatMoneyFull(item.valuationPrice, 0)
            ) : (
              // <span style={{ color: "#999" }}>Not Set</span>
              <>-</>
            )}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {item.valuationPriceSource ? (
              formatSource(item.valuationPriceSource)
            ) : (
              <span style={{ color: "#999" }}>Not Set</span>
            )}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {isMediumWidth
              ? formatDateDDMMYY(item.valuationDate)
              : formatDateDDMMMYYY(item.valuationDate)}
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer
      component={Paper}
      className="flex-center-center nice-scrollbar"
      style={{
        height: "100%",
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <LWTableHeader />
        </TableHead>
        <TableBody className={classes.body}>
          {renderedReportHistory?.map((item) => (
            <Row key={item.valuationId} item={item} className={classes.row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportHistoryTable;
