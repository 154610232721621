import React from "react";

const postcodeRegex = /([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;

const PropertyAddress = ({ property }) => {
  let display = property.addressLong || property.address;
  display = display.trim();
  if (
    !!property.postcode &&
    !display.endsWith(property.postcode) &&
    !display.match(postcodeRegex)
  ) {
    if (display.endsWith(property.postcodeDistrict)) {
      display = display.slice(
        0,
        display.length - property.postcodeDistrict.length
      );
    } else {
      display += ", ";
    }
    display += property.postcode;
  }

  return (
    <div
      style={{ userSelect: "text" }}
      className="property-address-div"
      onClick={(e) => {
        const selectionLength = window.getSelection().toString().length;
        if (selectionLength > 0) e.stopPropagation();
      }}
    >
      {display}
    </div>
  );
};

export default PropertyAddress;
