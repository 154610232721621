import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { getCreditData } from "graphql/functions";
import { getCreditData as getCreditDataRedux } from "data/selectors";

import { useSelector, useDispatch } from "react-redux";
import { setCreditsDataRedux } from "data/slices/creditsSlice";

const { Components } = require("@landworth/fiverr-chiran-landworth");

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const HeaderHolder = ({ history, isLoggedIn, handleSignOut }) => {
  const pathname = history?.location?.pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      delay(1000).then(
        getCreditData({
          initiator: pathname,
        }).then((creditData) => {
          const data = JSON.parse(creditData);
          dispatch(
            setCreditsDataRedux({
              creditsRemaining: data.credits_remaining,
              creditsMonthly: data.credits_monthly,
              stripeCustomerId: data.stripe_customer_id || "",
              stripePriceId: data.stripe_subscription_price_id || "",
              hasPaidMoney: data.has_paid_money || "",
              orgId: data.org_id || "",
              alphaTester: data.alpha_tester || false,
              betaTester: data.beta_tester || false,
            })
          );
        })
      );
    }
  }, [pathname, isLoggedIn, dispatch]);

  const creditData = useSelector((state) => getCreditDataRedux(state));

  useEffect(() => {
    if (isLoggedIn && creditData.orgId) {
      window.analytics.group(creditData.orgId, {
        traits: {
          name: creditData.orgId,
          creditsMonthly: creditData.creditsMonthly,
          hasPaidMoney: creditData.hasPaidMoney,
          stripeCustomerId: creditData.stripeCustomerId,
        },
      });
    }
  }, [creditData.orgId]);

  return (
    <Components.Header
      isLoggedIn={isLoggedIn}
      signout={handleSignOut}
      creditsRemaining={
        isNaN(creditData?.creditsRemaining)
          ? creditData?.creditsRemaining
          : `${creditData?.creditsRemaining}`
      }
      creditsMonthly={
        isNaN(creditData?.creditsMonthly)
          ? creditData?.creditsMonthly
          : `${creditData?.creditsMonthly}`
      }
    />
  );
};

export default withRouter(HeaderHolder);
