import React, { useState, useMemo, useCallback, useEffect } from "react";

import { Pages } from "@landworth/fiverr-chiran-landworth";
import { useHistory, useRouteMatch } from "react-router";
import { runValuation, fetchUPRNDetails } from "graphql/functions";
import { useValuationSearchResults } from "data/hooks/useValuationSearch";
// import { useValuationSearchResultsClean } from "data/hooks/useValuationSearchResultsClean";
import {
  // capitaliseFirstLetterEveryWord,
  cleanOSPlacename,
  generateMatchAddress,
} from "services/formatting";

// const cleanMapboxPlacename = (name) => {
//   let displayName;
//   if (name?.indexOf(", United Kingdom") > 0)
//     displayName = name.substring(0, name.indexOf(", United Kingdom"));
//   if (displayName?.indexOf(", England") > 0) {
//     displayName = displayName.substring(0, displayName.indexOf(", England"));
//   }

//   return displayName;
// };

const epcBuiltFormToPropertyTypeParent = (type, form) => {
  return type === "flat" || type === "maisonette"
    ? "flat"
    : form.toLowerCase().includes("semi")
    ? "semi"
    : type === "bungalow" || form.toLowerCase().includes("detached")
    ? "detached"
    : form.toLowerCase().includes("terrace")
    ? "terraced"
    : "UNKNOWN";
};

const filterByMatch = (results) => {
  // Filters out lower rated matches to compensate for messy results when searching by postcode
  const topMatchScore =
    results.location?.results?.length > 0 &&
    results.location.results[0].LPI.MATCH;

  const filteredResults = results.location?.results?.filter((result) => {
    return result.LPI.MATCH >= topMatchScore;
  });
  // console.log(filteredResults);
  return filteredResults;
};

const LocationSearchHolder = ({ setLocalLocationSpec }) => {
  const matchParams = useRouteMatch(
    "/report/referral/:referringDomainName"
  )?.params;
  const referringDomainName = matchParams?.referringDomainName;

  const history = useHistory();
  useEffect(() => {
    document.title = "Landworth: New Valuation Report";
  }, []);

  const [searchText, setSearchText] = useState("");

  // const { tooSmall, cleanResults, loading } = useValuationSearchResultsClean(searchText);
  const { tooSmall, results } = useValuationSearchResults(searchText);

  const filteredResults = filterByMatch(results);

  const propertySelected = useCallback(
    async (selectedOption) => {
      console.log(selectedOption);
      if (selectedOption) {
        const searchMode = !!selectedOption.hmlrid
          ? "landreg"
          : !!selectedOption.lmk_key
          ? "epc"
          : !!selectedOption.LPI?.UPRN
          ? "osuprn"
          : "location";

        // console.log("selected", { selectedOption, searchMode });
        const {
          hmlrid,
          lmk_key,
          address,
          postcode,
          total_floor_area,
          property_type,
          lat,
          lng,
        } = selectedOption;
        window.analytics.track(
          `Valuation - Select Address (${searchMode} RECORD)`,
          {
            hmlrid: hmlrid,
            uprn: selectedOption.LPI?.UPRN,
            uprnAddress: selectedOption.LPI?.ADDRESS,
            postcode: selectedOption.LPI?.POSTCODE_LOCATOR,
            searchText,
          }
        );

        if (searchMode === "landreg" || searchMode === "epc") {
          const valuationParameters = {
            valuationName:
              searchMode === "epc" ? `${address}, ${postcode}` : address,
            propertyType: property_type,
            ...(total_floor_area && {
              sqft: Math.round(total_floor_area * 10.7639),
            }),
            hmlrid,
            lmk_key,
            postcode,
            lat,
            lng,
          };
          const newValuationId = await runValuation({
            valuationMode: searchMode,
            valuationInputSpec: valuationParameters,
          });
          history.push(`/report/${newValuationId}`);
        } else if (searchMode === "osuprn") {
          // console.log("OS UPRN MODE");

          const { LNG, LAT, POSTCODE_LOCATOR, UPRN, CLASSIFICATION_CODE } =
            selectedOption.LPI;
          const uprnDetails = await fetchUPRNDetails({
            uprn: UPRN,
            postcode: POSTCODE_LOCATOR,
            matchAddress: generateMatchAddress(selectedOption.LPI),
          });
          // console.log(uprnDetails);
          if (!!uprnDetails?.property_type) {
            const {
              property_type,
              built_form,
              total_floor_area,
              lmk_key,
              inspection_date,
              current_energy_rating,
              current_energy_efficiency,
              potential_energy_rating,
              potential_energy_efficiency,
              local_authority_id,
              local_authority_name,
              floor_level,
              construction_age_band,
              beds,
            } = uprnDetails;

            setLocalLocationSpec({
              valuationName: cleanOSPlacename(selectedOption.LPI),
              lat: LAT,
              lng: LNG,
              postcode: POSTCODE_LOCATOR,
              uprn: UPRN,
              matchAddress: generateMatchAddress(selectedOption.LPI),
              propertyType: built_form,
              propertyTypeParent: epcBuiltFormToPropertyTypeParent(
                property_type,
                built_form
              ),
              size: Math.round(total_floor_area),
              sizeUnit: "sqm",
              epcSqm: Math.round(total_floor_area),
              lmk_key,
              inspection_date,
              currentEnergyRating: current_energy_rating,
              currentEnergyScore: current_energy_efficiency,
              potentialEnergyRating: potential_energy_rating,
              potentialEnergyScore: potential_energy_efficiency,
              local_authority_id,
              local_authority_name,
              floor_level,
              construction_age_band,
              referringDomainName,
              beds: parseInt(beds),
              lastSalePrice: uprnDetails.lastSalePrice,
              lastSaleDate: uprnDetails.lastSaleDate,
            });
          } else {
            setLocalLocationSpec({
              valuationName: cleanOSPlacename(selectedOption.LPI),
              matchAddress: generateMatchAddress(selectedOption.LPI),
              lat: LAT,
              lng: LNG,
              postcode: POSTCODE_LOCATOR,
              ...(CLASSIFICATION_CODE === "RD06" && {
                // Only use OSPlacesAPI property type when = "flat" as house types are unreliable
                propertyTypeParent: "flat",
              }),
              referringDomainName,
              uprn: UPRN,
              lastSalePrice: uprnDetails.lastSalePrice,
              lastSaleDate: uprnDetails.lastSaleDate,
            });
          }
          history.push(`/report/edit`);
        } else if (searchMode === "location") {
          // console.log("LOCATION MODE");
          // console.log(selectedOption);
          const {
            place_name,
            center: [lng, lat],
            postcode,
          } = selectedOption;
          setLocalLocationSpec({
            valuationName: `New Property at ${postcode}`,
            lat,
            lng,
            postcode,
          });
          history.push(`/report/edit`);
        } else {
          console.error("SELECTION OPTION HAS AN INVALID MODE!!");
        }
      }
    },
    [history]
  );

  const sortedResults = filteredResults?.sort((a, b) => {
    const aSortAddress = `${a.LPI.PAO_START_NUMBER || a.LPI.PAO_START_TEXT}${
      a.LPI.PAO_START_SUFFIX ?? ""
    } ${a.LPI.STREET_DESCRIPTION}`;
    const bSortAddress = `${b.LPI.PAO_START_NUMBER || b.LPI.PAO_START_TEXT}${
      b.LPI.PAO_START_SUFFIX ?? ""
    } ${b.LPI.STREET_DESCRIPTION}`;

    return parseInt(a.LPI.PAO_START_NUMBER) < parseInt(b.LPI.PAO_START_NUMBER)
      ? -1
      : parseInt(a.LPI.PAO_START_NUMBER) > parseInt(b.LPI.PAO_START_NUMBER)
      ? 1
      : aSortAddress < bSortAddress
      ? -1
      : 1;
  });

  const newPropertyTopResult = useMemo(() => {
    const osEntry =
      sortedResults?.length > 0 && sortedResults[0] && sortedResults[0].LPI;
    return osEntry
      ? {
          context: true,
          newPropertyShortAddress: `${osEntry.POSTCODE_LOCATOR}`,
          newAddress: searchText,
          address: searchText,
          place_name: cleanOSPlacename(osEntry),
          center: [osEntry.LNG, osEntry.LAT],
          postcode: osEntry.POSTCODE_LOCATOR,
          LPI: {
            POSTCODE_LOCATOR: osEntry.POSTCODE_LOCATOR,
          },
        }
      : {};
  }, [sortedResults]);

  const typeMoreLettersResult = {
    title: "Enter at least 5 characters...",
    icon: "keyboard",
  };

  const optionsDynamic = useMemo(() => {
    return searchText?.length < 5
      ? [typeMoreLettersResult]
      : sortedResults?.length > 0
      ? [newPropertyTopResult, ...sortedResults]
      : results.location.loading
      ? [{ title: <span className="vr-search-no-results">Loading...</span> }]
      : !!results.location?.header
      ? [
          {
            title: (
              <span className="vr-search-no-results" style={{ color: "red" }}>
                Nothing found
              </span>
            ),
            error: true,
          },
        ]
      : [];
  }, [results]);

  return (
    <>
      <Pages.LocationSearch
        options={optionsDynamic}
        loading={results.location.loading}
        searchText={searchText}
        handleChangeInput={setSearchText}
        handleSelectLocation={propertySelected}
      />
    </>
  );
};

export default LocationSearchHolder;
