import { useEffect, useMemo, useState } from "react";
import useSearchResults from "./useSearchResults";
import sortProperties from "../../pages/Search/sortProperties";
import Debug from "debug";
import { useSelector } from "react-redux";
import {
  getPropertySelector,
  getSearchSelector,
  getValuationSelector,
} from "data/selectors";
// import { getAnalysedProperties } from "services/propertyHelpers";

const debug = Debug("lw:useSortedSearchResults");

/**
 * Convencience hook for getting sorted results
 */
const useSortedSearchResults = (searchId, sortMode, lat, lng) => {
  const [properties, propertyIds] = useSearchResults(searchId);

  const search = useSelector((state) => getSearchSelector(state, searchId));
  const valuationId = search.parentValuationId;
  const valuation = useSelector((state) =>
    getValuationSelector(state, valuationId)
  );
  const valuationProperty = useSelector((state) =>
    getPropertySelector(state, valuation?.valuationPropertyId)
  );

  // const [totalProcessedProperties, setTotalProcessedProperties] = useState(0);
  // useEffect(() => {
  //   if (properties.length > 0) {
  //     const total = getAnalysedProperties(search, properties).length;
  //     setTotalProcessedProperties(total);
  //   }
  // }, [properties]);

  // only re-sort properties when a variable changes
  const sortedProperties = useMemo(() => {
    if (!!sortMode) {
      debug(`Sorting by ${sortMode}`);

      return sortProperties({
        properties,
        sortMode,
        searchLat: lat,
        searchLng: lng,
        valuation,
        valuationProperty,
      });
    } else {
      return properties;
    }
  }, [properties, sortMode]);

  return [sortedProperties, propertyIds];
};

export default useSortedSearchResults;
