import Chip from "@material-ui/core/Chip";
import useSearchFilter from "pages/Search/useSearchFilter";
// import tagDefinitions from "./tagger-tag-pro-definitions.json";

const TagChips = ({ property, size }) => {
  const { propertyID, tags, rmHistoryTags, zHistoryTags } = property;
  // const jsonToArray = (json) => {
  //   let array = [];
  //   Object.values(json).map((v) => array.push(v));
  //   return array;
  // };

  // const proTagList = jsonToArray(tagDefinitions.pro).flat(2);

  const [searchFilter, setSearchFilter] = useSearchFilter();

  const handleClick = (e) => {
    e.stopPropagation();
    const tag = e.target.textContent;
    window.analytics.track("Clicked Tag for filter", { tag });
    setSearchFilter({ keyword: tag });
  };

  const activeTags = tags
    ? Array.from(
        new Set([
          ...tags,
          ...(property.appType === "Trees" ? ["Tree Work"] : []),
        ])
      )
    : Array.from(new Set([...(rmHistoryTags || []), ...(zHistoryTags || [])]));

  return (
    <>
      <div
        key={propertyID + "_tags"}
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          overflowX: "visible",
        }}
      >
        <div>
          {activeTags
            .slice()
            .sort()
            .map((tag) => {
              // const proTag = proTagList.includes(tag);
              return (
                <Chip
                  style={{
                    margin: "2px 2px 1px 2px",
                    backgroundColor: "#f0f0f0",
                    fontSize: "0.85em",
                  }}
                  label={tag}
                  key={propertyID + "_" + tag}
                  color={"default"}
                  size={size}
                  onClick={handleClick}
                />
              );
            })}
          {activeTags.length === 0 && (
            <div className="missing-attribute-text">No tags found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TagChips;
