import { createSlice } from "@reduxjs/toolkit";
import Debug from "debug";
import { convertLrPropertyTypeToFullText } from "services/propertyHelpers";

const debug = Debug("lw:propertyReducer");

// TODO: right now the cache is an object implementation that could potentially grow infitintely
// this might eventually degrade or break the browser if the object has 1000s of properties in it
// we could use a LRU implementation to find a sweet spot cache size
// https://www.npmjs.com/package/lru-cache

const calculateLandworthScore = (landworth, price, sqft, lwsd) => {
  let score;
  if (!landworth || !sqft) {
    score = 0;
  } else if (landworth === -1 || sqft === -1 || price === 0) {
    score = -1;
  } else {
    score = ((price / sqft - landworth) / (lwsd * 2)) * 100;
    // This value is NEVER a whole number, so we can use 0 and -1 above as indications of waiting/error for LW score
  }

  return score;
};

const calculateLatestPrice = (price, landworth, latestLandworth) => {
  return Math.round(price * (latestLandworth / landworth));
};
const calculateLatestPpsf = (price, sqft, landworth, latestLandworth) => {
  return Math.round((price / sqft) * (latestLandworth / landworth));
};

const calculateLastSalePrice = (props) => {
  const {
    lastSaleLandworth,
    landworth,
    lastSaleLwTotalWeight,
    lwTotalWeight,
    lastSalePrice,
  } = props;
  const price = (landworth / lastSaleLandworth) * lastSalePrice;
  if (lastSaleLwTotalWeight > 5 && lwTotalWeight > 5) {
    return Math.round(price);
  } else {
    return -1;
  }
};

const enhance = ({
  price,
  sqft,
  landworth,
  lwsd,
  latestLandworth,
  latestLwTotalWeight,
  propertySource,
  listingStatus,
  propertyType,
  floorLevel,
  epcConstructionAgeBand,
  lrSoldPriceHistory,
  lastSalePrice,
  lastSaleLandworth,
  lastSaleLwTotalWeight,
  lwTotalWeight,
  // planningAppSizes,
  // planningAppTypes,
  // planningAppStates,
  ...rest
}) => ({
  ...rest,
  price,
  sqft,
  landworth,
  latestLandworth,
  latestLwTotalWeight,
  lwsd,
  ppsf: parseFloat(price / sqft),
  ppsfpyr: parseFloat((12 * price) / sqft),
  landworthScore: calculateLandworthScore(landworth, price, sqft, lwsd),
  latestLwEstimatedPrice: calculateLatestPrice(
    price,
    landworth,
    latestLandworth
  ),
  latestPpsf: calculateLatestPpsf(price, sqft, landworth, latestLandworth),
  propertySource,
  listingStatus,
  isUnderOffer:
    (propertySource === "rightmove" || propertySource === "zoopla") &&
    listingStatus === "sold"
      ? true
      : false,
  propertyType,
  lrPropertyType: convertLrPropertyTypeToFullText(propertyType),
  floorLevel:
    floorLevel && floorLevel === "-1"
      ? "Basement"
      : floorLevel?.length > 1
      ? floorLevel.replace(/^0/, "")
      : floorLevel,
  lrSoldPriceHistory,

  // Need to change to constructionYear
  epcConstructionAgeBand:
    lrSoldPriceHistory &&
    Array.isArray(lrSoldPriceHistory) &&
    JSON.parse(lrSoldPriceHistory).length > 0 &&
    JSON.parse(lrSoldPriceHistory).filter((d) => d.newbuild === "Y").length > 0
      ? new Date(
          JSON.parse(lrSoldPriceHistory).filter(
            (d) => d.newbuild === "Y"
          )[0].dateSold
        )
          .toDateString()
          .split(" ")[3]
      : epcConstructionAgeBand
      ? epcConstructionAgeBand
      : null,

  lastSalePrice,
  lastSaleLandworth,
  lastSaleLwTotalWeight,
  lwTotalWeight,
  lastSaleEstimatedPrice: calculateLastSalePrice({
    lastSalePrice,
    lastSaleLandworth,
    lastSaleLwTotalWeight,
    landworth: latestLandworth ?? landworth,
    lwTotalWeight: latestLwTotalWeight ?? lwTotalWeight,
  }),

  // planningAppSizes: planningAppSizes ? planningAppSizes : [],
  // planningAppTypes: planningAppTypes ? planningAppTypes : [],
  // planningAppStates: planningAppStates ? planningAppStates : [],
});

// TODO: this is failing under duress if cacheEntry is undefined
const giveUpOn = (state, propertyIds) => {
  propertyIds.reduce(
    (acc, propertyId) => {
      debug("Giving up on", propertyId);
      const c = acc[propertyId];

      if (c && !c.price) c.price = -1;
      if (c && !c.landworth) c.landworth = -1;
      if (c && !c.sqft) c.sqft = -1;
      if (c && !c.postcodeDistrict) c.postcodeDistrict = -1;
      if (c && !c.rmHistoryURL) c.rmHistoryURL = -1;
      if (c && !c.lrSoldPriceHistoryURL) c.lrSoldPriceHistoryURL = -1;
      if (c && !c.zHistoryURL) c.zHistoryURL = -1;
      if (c && !c.landworthScore) c.landworthScore = -1;

      acc[propertyId] = enhance(c);
      return acc;
    },
    {
      ...state,
    }
  );
};

const combinePropertiesWithCache = (state, properties) =>
  properties.reduce(
    (acc, property) => {
      acc[property.propertyID] = enhance(property);
      return acc;
    },
    {
      ...state,
    }
  );

// starProperty({ id: 'xxxx', isStarred: true})

const setStarStatus = (state, id, isStarred) => {
  const propertyToStar = state[id];
  return {
    ...state,
    [`${id}`]: {
      ...propertyToStar,
      starred: isStarred,
    },
  };
};

const starPropertyById = (state, propertyId) =>
  setStarStatus(state, propertyId, true);
const unstarPropertyById = (state, propertyId) =>
  setStarStatus(state, propertyId, false);

const setHideStatus = (state, id, isHidden) => {
  const propertyToHide = state[id];
  return {
    ...state,
    [`${id}`]: {
      ...propertyToHide,
      hidden: isHidden,
    },
  };
};

const hidePropertyById = (state, propertyId) =>
  setHideStatus(state, propertyId, true);
const unhidePropertyById = (state, propertyId) =>
  setHideStatus(state, propertyId, false);

const setPropertySqftById = (state, { propertyID, sqftValue }) => {
  const propertyToUpdate = state[propertyID];
  const { price, landworth, lwsd } = propertyToUpdate;
  return {
    ...state,
    [`${propertyID}`]: {
      ...propertyToUpdate,
      sqft: sqftValue,
      ppsf: parseFloat(price / sqftValue),
      landworthScore: calculateLandworthScore(
        landworth,
        price,
        sqftValue,
        lwsd
      ),
    },
  };
};

const propertiesSlice = createSlice({
  name: "properties",
  initialState: {},
  reducers: {
    addPropertiesToCache(state, { payload }) {
      return combinePropertiesWithCache(state, payload);
    },
    giveUpOnProperties(state, { payload }) {
      return giveUpOn(state, payload);
    },
    starProperty(state, { payload }) {
      return starPropertyById(state, payload);
    },
    unstarProperty(state, { payload }) {
      return unstarPropertyById(state, payload);
    },
    hideProperty(state, { payload }) {
      return hidePropertyById(state, payload);
    },
    unhideProperty(state, { payload }) {
      return unhidePropertyById(state, payload);
    },
    setPropertySqft(state, { payload }) {
      return setPropertySqftById(state, payload);
    },
  },
});

export const {
  addPropertiesToCache,
  giveUpOnProperties,
  starProperty,
  unstarProperty,
  hideProperty,
  unhideProperty,
  setPropertySqft,
  setPropertyComparable,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
