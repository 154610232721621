import React from "react";
import "./landworthIndicator.css";
import {
  formatDateDDMMMYYY,
  capitaliseFirstLetter,
} from "../services/formatting";
import PropertyLandworthScore from "./PropertyLandworthScore";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import useUnits from "data/hooks/useUnits";

const barColors = ["#178d17", "#3f9878", "#35a4ce", "#335885", "#1f1243"];

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 16,
    height: 10,
    padding: 0,
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(6px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#35a4ce",
        borderColor: "#35a4ce",
      },
    },
  },
  thumb: {
    width: 6,
    height: 6,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const LandworthIndicator = (props) => {
  const { property, landworthData, value, date, propertyTypeParent } = props;
  const { displayUnits, setDisplayUnits, units } = useUnits();

  let indicatorTitle = `Average${
    propertyTypeParent && propertyTypeParent !== "other"
      ? ` ${capitaliseFirstLetter(propertyTypeParent)}`
      : ""
  } Sold Price`;
  if (date) indicatorTitle += ` on ${formatDateDDMMMYYY(date)}`;

  const minValue = landworthData.worth - 2 * landworthData.stdev;
  const maxValue = landworthData.worth + 2 * landworthData.stdev;

  const leftLabel = value
    ? "-100%"
    : `£${Math.round(
        displayUnits === "ppsm"
          ? (landworthData.worth - landworthData.stdev) * 10.7639
          : landworthData.worth - landworthData.stdev
      )?.toLocaleString()}/${units}`;

  const averageLabel = `${Math.round(
    displayUnits === "ppsm"
      ? landworthData.worth * 10.7639
      : landworthData.worth
  )?.toLocaleString()}/${units}`;

  const rightLabel = value
    ? "+100%"
    : `£${Math.round(
        displayUnits === "ppsm"
          ? (landworthData.worth + landworthData.stdev) * 10.7639
          : landworthData.worth + landworthData.stdev
      )?.toLocaleString()}/${units}`;

  const valueLabel = Math.round(
    displayUnits === "ppsm" ? value * 10.7639 : value
  ).toLocaleString();

  const valueMarkerShift =
    (value <= minValue
      ? 0
      : value >= maxValue
      ? 260
      : ((value - minValue) / (4 * landworthData.stdev)) * 260) - 12;

  const toggleUnits = (newState) => {
    window.analytics.track("Toggle Units", {
      units: newState ? "sqm" : "sqft",
    });
    setDisplayUnits(newState ? "ppsm" : "ppsf");
  };

  return (
    <div className="lwContainer">
      <div id="v4mContainerLarge">
        <div id="v4mIndicator">
          <div className="v4mMiddle">
            <div className="v4mMiddleTop">
              <div className="v4mLowLabel"></div>
              <div className="v4mTitle">{indicatorTitle}</div>
              {!property && (
                <div className="v4mHighLabel">
                  <span
                    onClick={() => toggleUnits(displayUnits !== "ppsm")}
                    style={{ cursor: "pointer" }}
                  >
                    ft²&nbsp;
                  </span>
                  <AntSwitch
                    checked={displayUnits === "ppsm"}
                    color="secondary"
                    onChange={() => toggleUnits(displayUnits !== "ppsm")}
                    name="sqftsqm"
                  />
                  <span
                    onClick={() => toggleUnits(displayUnits !== "ppsm")}
                    style={{ cursor: "pointer" }}
                  >
                    &nbsp;m²
                  </span>
                </div>
              )}
            </div>
            <div className="v4mBarFigures">
              <div className="v4mLowValue">
                <span>{leftLabel}</span>
              </div>
              <div className="v4mValueMarker">
                <span>£{averageLabel}</span>
              </div>
              <div className="v4mHighValue">
                <span>{rightLabel}</span>
              </div>
            </div>

            <div className="v4mBar">
              <div className="colorBar">
                {barColors.map((color) => (
                  <span
                    key={color}
                    className="gradstep"
                    style={{ backgroundColor: color }}
                  ></span>
                ))}
                <span className="gradstep"></span>
              </div>
            </div>

            {value && (
              <div className="v4mMiddleBottom">
                <div
                  className="v4mMarkerBottomContainer"
                  style={{ left: `${valueMarkerShift}px` }}
                >
                  <div className="v4mMarkerBottom"></div>
                  <div className="v4mMarkerLabel">
                    <PropertyLandworthScore property={property} />
                    {property.ppsf > 0 &&
                      Number.isFinite(property.ppsf) &&
                      `£${valueLabel}/${units}`}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandworthIndicator;
