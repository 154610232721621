import { useEffect } from "react";
import { signInFormFields, signInComponents } from "./signInObjects";
import { signUpFormFields, signUpComponents } from "./signUpObjects";

import { Auth, I18n } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "./authTheme.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { isValidDomain } from "./authServices";

I18n.putVocabulariesForLanguage("en", {
  "Sign In": "Log In", // Tab header
  "Sign in": "Enter Landworth", // Button label
  "Sign in to your account": "Welcome Back!",
  Username: "Enter your username", // Username label
  Password: "Enter your password", // Password label
  "Forgot your password?": "Reset Password",
});

const formFields = {
  ...signInFormFields,
  ...signUpFormFields,
};

const components = {
  ...signInComponents,
  ...signUpComponents,
};

const NewSignUpComponent = (props) => {
  const { route, user } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);
  const isLoggedIn = !!(route === "authenticated" && user);

  useEffect(() => {
    if (isLoggedIn) window.location.href = "/";
  }, [isLoggedIn]);

  const referrer = props?.match?.params?.referrer;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  useEffect(() => {
    window.analytics.page("Load Signup Page", { referrerUsername: referrer });
  }, [referrer]);

  const services = {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      const emailDomain = username.split("@")[1];
      attributes["custom:referrer"] = referrer;

      if (!isValidDomain(emailDomain)) {
        window.analytics.track("Blocked Sign-up Attempt", {
          username,
          emailDomain,
        });
        throw new Error("Sign-ups from this email domain are not allowed");
      } else {
        return Auth.signUp({
          name: attributes.name,
          username,
          password,
          attributes,
        });
      }
    },
  };

  let dynamicFormFields = {
    ...formFields,
    ...(!!email && {
      signUp: {
        ...formFields.signUp,
        email: { ...formFields.signUp.email, defaultValue: email },
      },
    }),
  };

  return (
    <div
      className="flex-center-center nice-scrollbar"
      style={{
        flex: "1 1 auto",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        borderTop: "1px solid grey",
        justifyContent: "flex-start",
      }}
    >
      <Authenticator
        loginMechanisms={["email"]}
        initialState={"signUp"}
        formFields={dynamicFormFields}
        components={components}
        services={services}
      >
        {props.children}
      </Authenticator>
    </div>
  );
};
export default NewSignUpComponent;
