import * as FileSaver from "file-saver";
import {
  formatDateDDMMYYY,
  formatMoneyFull,
  capitaliseFirstLetter,
} from "services/formatting";
import { convertSpecToReadable } from "services/search";
import {
  measurePropertiesPpsfAverage,
  measurePropertiesPriceAverage,
  measurePropertiesLatestPpsfAverage,
  measurePropertiesLatestPriceAverage,
  measurePropertiesSqftAverage,
  measurePropertiesPpsmAverage,
} from "services/propertyHelpers";

import { getDistanceBetweenTwoPoints } from "services/geocoding";

const ExcelJS = require("exceljs");

const downloadProperties = async (
  properties,
  search,
  searchId,
  showTodayPrice,
  units,
  displayUnits
) => {
  console.log("Downloading results as csv...");
  const comparableProperties = JSON.parse(search.comparableProperties);

  console.log(convertSpecToReadable(search));
  let nowDateTime = new Date().toUTCString().slice(0, -4);
  let nowDate = new Date().toUTCString().slice(0, -13);
  console.log(nowDate);
  let filename = `Landworth Comparable Report for ${search.name} - ${properties.length} Results on ${nowDateTime}`;

  const workbook = new ExcelJS.Workbook();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: "visible",
    },
  ];

  const includeCompColumn =
    comparableProperties && comparableProperties.length > 0;

  const isSaleSearch = search?.spec?.search_type === "sale";

  const overviewSheet = workbook.addWorksheet("Overview", {
    views: [],
  });
  overviewSheet.columns = [];

  overviewSheet.addRow([`Landworth Comparables Report: Created on ${nowDate}`]);
  overviewSheet.getRow(1).font = { bold: true, size: 20 };
  overviewSheet.addRow([]);
  overviewSheet.addRow([convertSpecToReadable(search)]);
  overviewSheet.getRow(3).font = { bold: true };

  overviewSheet.getColumn(1).width = 30;
  overviewSheet.getColumn(2).width = 15;
  overviewSheet.getColumn(2).alignment = { horizontal: "left" };
  overviewSheet.getColumn(2).font = { bold: true };

  console.log(window);
  console.log(window.location);
  const searchUrl = `${window.location.origin}/search/${searchId}`;
  overviewSheet.addRow([{ text: `${searchUrl}`, hyperlink: searchUrl }]);
  overviewSheet.getCell("A4").font = {
    color: { argb: "FF0000FF" },
    underline: "single",
    bold: true,
  };
  overviewSheet.addRow([
    {
      text: `See Property List`,
      hyperlink: "#'Property List'!A1",
    },
  ]);
  overviewSheet.getCell("A5").font = {
    color: { argb: "FF0000FF" },
    underline: "single",
    bold: true,
  };

  const averageResultPrice = measurePropertiesPriceAverage(
    search?.spec?.search_type,
    properties
  );
  const averagePpsf = measurePropertiesPpsfAverage(
    search?.spec?.search_type,
    properties
  );
  const averagePpsm = measurePropertiesPpsmAverage(
    search?.spec?.search_type,
    properties
  );
  const averageResultSqft = measurePropertiesSqftAverage(
    search?.spec?.search_type,
    properties
  );

  overviewSheet.addRow([]);
  overviewSheet.addRow([]);
  overviewSheet.addRow([
    "Total Search Results",
    `${properties.length} Results`,
  ]);
  overviewSheet.addRow([
    "Average Size",
    `${
      displayUnits === "ppsm"
        ? Math.round(averageResultSqft / 10.7639)
        : averageResultSqft
    } ${units}`,
  ]);
  overviewSheet.addRow([
    `Average £/${units}${!isSaleSearch ? "/yr" : ""}`,
    `£${displayUnits === "ppsm" ? averagePpsm : averagePpsf}/${units}${
      !isSaleSearch ? "/yr" : ""
    }`,
  ]);
  overviewSheet.addRow([
    `Average Price`,
    `£${formatMoneyFull(averageResultPrice, 0)}${
      !isSaleSearch ? "/month" : ""
    }`,
  ]);
  // overviewSheet.getCell("B10").style = { numFmt: '"£"#,##0' };
  overviewSheet.getCell("B11").numFmt = '"£"#,##0';

  if (includeCompColumn) {
    const comps = properties.filter((prop) =>
      comparableProperties.includes(prop.propertyID)
    );
    const totalComps = comparableProperties ? comparableProperties.length : "-";
    const compsToAverage = isSaleSearch
      ? comps.filter((prop) => prop.ppsf > 25 && prop.ppsf < 5000)
      : comps.filter((prop) => prop.ppsfpyr > 0 && prop.ppsfpyr < 1000);

    const compsAveragePrice = measurePropertiesPriceAverage(
      search?.spec?.search_type,
      comps
    );
    const compsAveragePriceDerivative = measurePropertiesPpsfAverage(
      search?.spec?.search_type,
      comps
    );
    const compsAverageResultSqft = measurePropertiesSqftAverage(
      search?.spec?.search_type,
      comps
    );

    // const todayCompsToAverage = compsToAverage.filter(
    //   (prop) => prop.latestLwEstimatedPrice > 0
    // );
    const compsAveragePriceToday = measurePropertiesLatestPriceAverage(
      search?.spec?.search_type,
      comps
    );
    const compsAveragePriceDerivativeToday = measurePropertiesLatestPpsfAverage(
      search?.spec?.search_type,
      comps
    );

    overviewSheet.addRow([]);
    overviewSheet.addRow(["Total Comparables", `${totalComps} Comps`]);
    overviewSheet.addRow([
      "Average Comp Size",
      `${
        displayUnits === "ppsm"
          ? Math.round(compsAverageResultSqft / 10.7639)
          : compsAverageResultSqft
      } ${units}`,
    ]);
    overviewSheet.addRow([
      `Average Comp £/${units}${!isSaleSearch ? "/yr" : ""}`,
      `£${
        displayUnits === "ppsm"
          ? Math.round(compsAveragePriceDerivative * 10.7639)
          : compsAveragePriceDerivative
      } /${units}${!isSaleSearch ? "/yr" : ""}`,
    ]);
    overviewSheet.addRow([
      `Average Comp Price`,
      `£${formatMoneyFull(compsAveragePrice, 0)}${
        !isSaleSearch ? "/month" : ""
      }`,
    ]);
    overviewSheet.getCell("B15").numFmt = '"£"#,##0';
    overviewSheet.getCell("B16").style = {
      numFmt: '"£"#,##0',
      font: { color: { argb: "FFFFFFFF" }, bold: true },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF592E9A" },
      },
    };

    if (showTodayPrice) {
      overviewSheet.addRow([]);
      // overviewSheet.addRow([
      //   "Total Sold Comparables Today",
      //   todayCompsToAverage.length || "-",
      // ]);
      overviewSheet.addRow([
        `TODAY Average Comp £/${units}`,
        `£${
          displayUnits === "ppsm"
            ? Math.round(compsAveragePriceDerivativeToday * 10.7639)
            : compsAveragePriceDerivativeToday
        } /${units}`,
      ]);
      overviewSheet.addRow([
        "TODAY Average Comp Price",
        `£${formatMoneyFull(compsAveragePriceToday, 0)}`,
      ]);
      overviewSheet.getCell("B18").numFmt = '"£"#,##0';
      overviewSheet.getCell("B19").style = {
        numFmt: '"£"#,##0',
        font: { color: { argb: "FFFFFFFF" }, bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF592E9A" },
        },
      };
    }
  }

  const propertyListWorksheet = workbook.addWorksheet("Property List", {
    views: [{ state: "frozen", xSplit: includeCompColumn ? 5 : 4, ySplit: 1 }],
  });

  propertyListWorksheet.columns = [
    ...(includeCompColumn
      ? [
          {
            header: "Comparable",
            key: "comparable",
            width: 15,
          },
        ]
      : []),
    { header: "Status", key: "listingStatus", width: 13 },
    { header: "Source", key: "propertySource", width: 13 },
    {
      header: `Price${!isSaleSearch ? "/month" : ""}`,
      key: "price",
      width: 12,
      style: { numFmt: '"£"#,##0' },
    },
    {
      header: "Address",
      key: "address",
      width: 50,
      alignment: { wrapText: false },
    },
    { header: "Postcode", key: "postcode", width: 10 },
    {
      header: "Dist (miles)",
      key: "distance",
      width: 11,
      style: { alignment: { horizontal: "center" } },
    },
    {
      header: "Beds",
      key: "beds",
      width: 6,
    },
    { header: "Type", key: "propertyType", width: 18 },
    {
      header: "New Build",
      key: "newbuild",
      width: 10,
      style: { alignment: { horizontal: "center" } },
    },

    {
      header: "Baths",
      key: "baths",
      width: 6,
      style: { alignment: { horizontal: "center" } },
    },
    {
      header: capitaliseFirstLetter(units),
      key: "sqft",
      width: 8,
      style: { alignment: { horizontal: "center" } },
    },
    ...(isSaleSearch
      ? [
          {
            header: `£/${units}`,
            key: "ppsf",
            width: 8,
            style: { alignment: { horizontal: "center" } },
          },
        ]
      : [
          {
            header: `£/${units}/yr`,
            key: "ppsfpyr",
            width: 8,
            style: { alignment: { horizontal: "center" } },
          },
        ]),
    {
      header: "± Avg %",
      key: "landworthScore",
      width: 10,
      style: { alignment: { horizontal: "center", wrapText: true } },
    },
    {
      header: "Tags",
      key: "tags",
      width: 25,
    },
    {
      header: "Sold Date",
      key: "dateSold",
      width: 15,
      style: { numFmt: "dd/mm/yyyy" },
    },
    {
      header: "Today Price",
      key: "latestLwEstimatedPrice",
      width: 13,
      style: { numFmt: '"£"#,##0' },
    },
    { header: `Today £/${units}`, key: "latestPpsf", width: 13 },
    { header: "Sold History Link", key: "historyListingURL", width: 50 },
    {
      header: "Listed Date",
      key: "dateFirstListed",
      width: 15,
      style: { numFmt: "dd/mm/yyyy" },
    },
    {
      header: "First Listing Price",
      key: "priceFirstListed",
      width: 15,
      style: { numFmt: '"£"#,##0' },
    },
    { header: "Listing Agent", key: "agentName", width: 25 },
    { header: "Listing Agent Phone", key: "agentPhone", width: 25 },
    { header: "Source Link", key: "listingURL", width: 50 },
    {
      header: "EPC Scores",
      key: "epcScores",
      width: 13,
      style: { alignment: { horizontal: "center" } },
    },
    {
      header: "EPC Floor",
      key: "epcFloor",
      width: 13,
      style: { alignment: { horizontal: "center" } },
    },
    { header: "EPC Link", key: "epcURL", width: 13 },
  ];

  propertyListWorksheet.getRow(1).font = {
    color: { argb: "FFFFFFFF" },
    bold: true,
  };
  propertyListWorksheet.getRow(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FF000000" },
  };
  propertyListWorksheet.getColumn("address").alignment = { wrapText: true };

  const columnToFormat = includeCompColumn ? "B" : "A";
  const columnToFormatLWScore = includeCompColumn ? "N" : "M";
  const columnToFormatPrice = includeCompColumn ? "D" : "C";
  const columnToFormatDistance = includeCompColumn ? "G" : "F";

  propertyListWorksheet.addConditionalFormatting({
    ref: `${columnToFormat}2:${columnToFormat}9999`,
    rules: [
      {
        type: "expression",
        formulae: [`=$${columnToFormat}2="Sold"`],
        style: {
          font: {
            bold: true,
            color: { argb: "FF800080" },
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormat}2="For Sale"`],
        style: {
          font: {
            bold: true,
            color: { argb: "FF008000" },
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormat}2="Under Offer"`],
        style: {
          font: {
            bold: true,
            color: { argb: "FF0000ff" },
          },
        },
      },
    ],
  });

  propertyListWorksheet.addConditionalFormatting({
    ref: `${columnToFormatPrice}2:${columnToFormatPrice}9999`,
    rules: [
      {
        type: "dataBar",
        cfvo: [{ type: "min" }, { type: "max" }],
        color: { argb: "FFFF8989" },
        gradient: false,
      },
    ],
  });

  propertyListWorksheet.addConditionalFormatting({
    ref: `${columnToFormatDistance}2:${columnToFormatDistance}9999`,
    rules: [
      {
        type: "colorScale",
        cfvo: [{ type: "min" }, { type: "max" }],
        color: [{ argb: "FF91B248" }, { argb: "FFFFFFFF" }],
      },
    ],
  });

  propertyListWorksheet.addConditionalFormatting({
    ref: `${columnToFormatLWScore}2:${columnToFormatLWScore}9999`,
    rules: [
      {
        type: "expression",
        formulae: [`=ISBLANK($${columnToFormatLWScore}2)`],
        style: {
          fill: {
            type: "pattern",
            pattern: "none",
          },
        },
      },
      {
        type: "expression",
        formulae: [`=ISTEXT($${columnToFormatLWScore}2)`],
        style: {
          fill: {
            type: "pattern",
            pattern: "none",
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormatLWScore}2<=-60`],
        style: {
          font: {
            bold: true,
            color: { argb: "FFFFFFFF" },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "FF178D17" },
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormatLWScore}2<=-20`],
        style: {
          font: {
            bold: true,
            color: { argb: "FFFFFFFF" },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "FF3F9878" },
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormatLWScore}2<=20`],
        style: {
          font: {
            bold: true,
            color: { argb: "FFFFFFFF" },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "FF35A5CE" },
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormatLWScore}2<=60`],
        style: {
          font: {
            bold: true,
            color: { argb: "FFFFFFFF" },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "FF335885" },
          },
        },
      },
      {
        type: "expression",
        formulae: [`=$${columnToFormatLWScore}2>60`],
        style: {
          font: {
            bold: true,
            color: { argb: "FFFFFFFF" },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "FF1F1243" },
          },
        },
      },
    ],
  });

  properties.forEach((prop) => {
    const newPropertyType =
      prop.propertyType === "F"
        ? "Flat"
        : prop.propertyType === "S"
        ? "Semi-detached"
        : prop.propertyType === "D"
        ? "Detached"
        : prop.propertyType === "T"
        ? "Terraced"
        : capitaliseFirstLetter(prop.propertyType);

    const numBeds = prop.beds || prop.rmBeds || prop.zBeds;
    const numBaths = prop.baths || prop.rmBaths || prop.zBaths;

    const listingStatusOutput =
      prop.propertySource === "landreg"
        ? "Sold"
        : prop.listingStatus === "for_sale"
        ? "For Sale"
        : prop.listingStatus === "for_rent"
        ? "For Rent"
        : prop.listingStatus === "rented"
        ? "Rented"
        : "Under Offer";

    const mergedTags = prop.tags
      ? prop.tags
      : Array.from(
          new Set([...(prop.rmHistoryTags || []), ...(prop.zHistoryTags || [])])
        );

    propertyListWorksheet.addRow({
      ...prop,
      address: prop.addressLong ? prop.addressLong : prop.address,
      distance:
        Math.round(
          getDistanceBetweenTwoPoints(
            { lat: prop.lat, lng: prop.lng },
            { lat: search.spec.searchlat, lng: search.spec.searchlng }
          ) * 1000
        ) / 1000,
      postcode: prop.postcode ? prop.postcode : prop.postcodeDistrict,
      beds: numBeds >= 0 ? numBeds : "",
      baths: numBaths >= 0 ? numBaths : "",

      ppsf:
        prop.sqft > 0 && prop.price
          ? displayUnits === "ppsm"
            ? Math.round(prop.ppsf * 10.7639)
            : Math.round(prop.ppsf)
          : "",
      ppsfpyr:
        prop.sqft > 0 && prop.price
          ? displayUnits === "ppsm"
            ? Math.round(prop.ppsfpyr * 10.7639)
            : Math.round(prop.ppsfpyr)
          : "",
      sqft:
        prop.sqft > 0
          ? displayUnits === "ppsm"
            ? Math.round(prop.sqft / 10.7639)
            : Math.round(prop.sqft)
          : "",
      landworth: Math.round(prop.landworth),
      landworthScore:
        prop.sqft > 0 && prop.price && prop.landworth > 0
          ? Math.max(Math.min(Math.round(prop.landworthScore), 100), -100)
          : "",
      latestLwEstimatedPrice:
        prop.latestLwEstimatedPrice > 0
          ? Math.round(prop.latestLwEstimatedPrice)
          : "",
      latestPpsf:
        prop.latestPpsf > 0
          ? displayUnits === "ppsm"
            ? Math.round(prop.latestPpsf * 10.7639)
            : Math.round(prop.latestPpsf)
          : "",
      newbuild: prop.newbuild ? "Yes" : "No",
      propertyType: newPropertyType,
      propertySource: capitaliseFirstLetter(prop.propertySource),
      dateSold: prop.dateSold ? formatDateDDMMYYY(prop.dateSold) : "",
      dateFirstListed:
        prop.dateFirstListed && formatDateDDMMYYY(prop.dateFirstListed),
      comparable:
        comparableProperties && comparableProperties.includes(prop.propertyID)
          ? "Yes"
          : "",
      listingStatus: listingStatusOutput,
      listingURL:
        prop.propertySource === "landreg"
          ? `https://landregistry.data.gov.uk/data/ppi/transaction/${prop.propertyID.toUpperCase()}/current`
          : prop.listingURL,
      historyListingURL:
        prop.rmHistoryURL === "-1" || prop.zHistoryURL === "-1"
          ? ""
          : prop.rmHistoryURL || prop.zHistoryURL,

      tags: mergedTags.join(", "),
      epcScores: prop.currentEnergyRating
        ? `${prop.currentEnergyScore}${prop.currentEnergyRating} → ${prop.potentialEnergyScore}${prop.potentialEnergyRating}`
        : "",
      epcFloor: prop.floorLevel,
      epcURL: prop.currentEnergyRating
        ? "https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=" +
          prop.postcode
        : "",
    });
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blobType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const blob = new Blob([data], { type: blobType });
    FileSaver.saveAs(blob, filename);
  });
};

export default downloadProperties;
