import { useRef, useState, useMemo } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import downloadProperties from "../pages/Search/Data/downloadProperties";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useDeviceWidth from "data/hooks/useDeviceWidth";
import useUnits from "data/hooks/useUnits";

const DownloadItem = ({ label, onClick }) => {
  return (
    <MenuItem
      key={label}
      variant="contained"
      color="primary"
      size="small"
      onClick={onClick}
      dense
    >
      {label}
    </MenuItem>
  );
};

const DownloadSearchResultsButton = ({
  searchId,
  search,
  properties,
  searchFinished,
  showTodayPrice,
  isOnReportUrl,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [isSmallWidth, isMediumWidth, isLargeWidth] = useDeviceWidth();
  const { displayUnits, units, convertedValuePriceSize } = useUnits();

  const isDisabled =
    !searchFinished || properties.length === 0 || search.spec.search_plan;

  const totalComps = search.comparableProperties
    ? JSON.parse(search.comparableProperties).length
    : 0;
  const lrFilteredProps = properties.filter(
    (prop) => prop.propertySource === "landreg"
  );
  const zFilteredProps = properties.filter(
    (prop) => prop.propertySource === "zoopla"
  );
  const rmFilteredProps = properties.filter(
    (prop) => prop.propertySource === "rightmove"
  );

  const downloadAll = () => {
    console.log("Download Search Results - All");
    window.analytics.track("Download Search Results - All", {
      searchId: searchId,
      totalProperties: properties.length,
      totalComps: totalComps,
    });
    downloadProperties(
      properties,
      search,
      searchId,
      showTodayPrice,
      units,
      displayUnits
    );
  };

  const downloadComps = () => {
    console.log("Download Search Results - Comps");
    window.analytics.track("Download Search Results - Comps", {
      searchId: searchId,
      totalProperties: properties.length,
      totalComps: totalComps,
    });
    const filteredProps = properties.filter((prop) =>
      search.comparableProperties.includes(prop.propertyID)
    );

    downloadProperties(filteredProps, search, searchId, showTodayPrice);
  };

  const downloadLR = () => {
    console.log("Download Search Results - LandReg");
    window.analytics.track("Download Search Results - LandReg", {
      searchId: searchId,
      totalProperties: properties.length,
    });
    downloadProperties(lrFilteredProps, search, searchId, showTodayPrice);
  };
  const downloadZoopla = () => {
    console.log("Download Search Results - Zoopla");
    window.analytics.track("Download Search Results - Zoopla", {
      searchId: searchId,
      totalProperties: properties.length,
    });
    downloadProperties(zFilteredProps, search, searchId, showTodayPrice);
  };
  const downloadRightmove = () => {
    console.log("Download Search Results - RM");
    window.analytics.track("Download Search Results - RM", {
      searchId: searchId,
      totalProperties: properties.length,
    });
    downloadProperties(rmFilteredProps, search, searchId, showTodayPrice);
  };

  const totalSources = useMemo(() => {
    const hasLrProps = lrFilteredProps.length > 0 ? 1 : 0;
    const hasRmProps = rmFilteredProps.length > 0 ? 1 : 0;
    const hasZProps = zFilteredProps.length > 0 ? 1 : 0;
    return hasLrProps + hasRmProps + hasZProps;
  }, [lrFilteredProps, rmFilteredProps, zFilteredProps]);

  return (
    <div className="download-search-results-button-div">
      <ButtonGroup
        variant={"outlined"}
        color="default"
        size="small"
        ref={anchorRef}
        aria-label="split button"
        disabled={isDisabled}
      >
        <Button
          onClick={downloadAll}
          className="download-search-results-button"
          style={{
            borderColor: isDisabled && "lightgrey",
            backgroundColor: "white",
            padding: "6px 12px 3px 12px",
          }}
        >
          {!isMediumWidth ? (
            <>
              <div style={{ marginRight: "8px" }}>Download</div>
              <FontAwesomeIcon
                icon={regular("file-xls")}
                style={{ fontSize: "1.1em", paddingBottom: "4px" }}
              />
            </>
          ) : (
            <>
              &nbsp;
              <FontAwesomeIcon
                icon={regular("file-xls")}
                style={{ fontSize: "1.1em", paddingBottom: "4px" }}
              />
              &nbsp;
            </>
          )}
        </Button>
        {totalSources > 1 && (
          <Button
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Download Results"
            aria-haspopup="menu"
            onClick={handleToggle}
            disabled={isDisabled}
            style={{
              color: "#212121",
              width: "30px",
              minWidth: "30px",
              borderColor: "rgba(0, 0, 0, 0.23)",
              backgroundColor: "white",
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "999" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <DownloadItem label={"All Sources"} onClick={downloadAll} />
                  {search.comparableProperties?.length > 0 && (
                    <DownloadItem
                      label={"Comparables"}
                      onClick={downloadComps}
                    />
                  )}
                  {lrFilteredProps.length > 0 && (
                    <DownloadItem
                      label={"LR Sold Prices"}
                      onClick={downloadLR}
                    />
                  )}
                  {rmFilteredProps.length > 0 && (
                    <DownloadItem
                      label={"Rightmove"}
                      onClick={downloadRightmove}
                    />
                  )}
                  {zFilteredProps.length > 0 && (
                    <DownloadItem label={"Zoopla"} onClick={downloadZoopla} />
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default DownloadSearchResultsButton;
