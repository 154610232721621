import pdf_document from "../assets/PDF_file_icon2.svg";
// Minified using @link https://jakearchibald.github.io/svgomg/

const PropertyFloorplan = ({ url, maxHeight }) => {
  return (
    <>
      {url && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          style={{ cursor: "alias", height: maxHeight }}
        >
          {url.slice(-4) === ".pdf" ? (
            <img
              src={pdf_document}
              alt="Floorplan"
              width="auto"
              style={{ maxHeight: "80px", maxWidth: "120px" }}
            />
          ) : (
            <img
              src={url}
              alt="Floorplan"
              width="auto"
              style={{
                maxHeight: maxHeight,
                maxWidth: "120px",
              }}
            />
          )}
        </a>
      )}
      {!url && (
        <span className="missing-attribute-text" width="120px">
          No Floorplan
        </span>
      )}
    </>
  );
};

export default PropertyFloorplan;
