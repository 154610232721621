import React from "react";
import {
  getLandworthScoreRating,
  getLandworthScoreColor,
} from "../services/formatting";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import TodayPriceWarning from "components/tooltips/TodayPriceWarning";
import Chip from "@material-ui/core/Chip";

const WarningChip = ({ label, height, width }) => (
  <Chip
    style={{
      fontSize: "0.7em",
      fontWeight: "400",
      width,
      height,
      textAlign: "center",
      backgroundColor: "#e0e0e0",
      color: "#000000de",
    }}
    label={<>{label}</>}
    color={"secondary"}
    size={"small"}
  />
);

const PropertyLandworthScore = ({ property, showTags = true }) => {
  const { landworthScore, price, tags, lwTotalWeight, sqft } = property;

  const limitedScore =
    landworthScore > 100
      ? 100
      : landworthScore < -100
      ? -100
      : Math.round(landworthScore);

  const priorityTags = [
    "Shared Ownership",
    "Retirement",
    "Auction",
    "Short Lease",
    "Park Home",
    "Knotweed",
  ];
  const hasPriorityTag = tags?.some((tag) => priorityTags.includes(tag));

  let display;
  if (!landworthScore) {
    display = (
      <LazyLoadComponent>
        <div className="loader-small"></div>
      </LazyLoadComponent>
    );
  } else if (landworthScore === -1 || property.landworth === -100) {
    if (!hasPriorityTag)
      display = <span className="missing-attribute-text">-</span>;
  } else if (sqft <= 0 && !hasPriorityTag) {
    display = <span className="missing-attribute-text">Need Size</span>;
  } else if (price === 0 && !hasPriorityTag) {
    display = <span className="missing-attribute-text">Need Price</span>;
  } else {
    display = `${landworthScore > 0 ? "+" : ""}${limitedScore}%`;
  }

  return (
    <div
      style={{
        color: price >= 0 ? getLandworthScoreColor(landworthScore) : "black",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!!landworthScore &&
        landworthScore !== -1 &&
        property.landworth !== -100 &&
        price > 0 && <span>{getLandworthScoreRating(landworthScore)}</span>}
      <span style={{ fontSize: "0.8em", fontWeight: "normal" }}>{display}</span>
      {lwTotalWeight > 0 && lwTotalWeight < 5 && sqft > 0 && (
        <TodayPriceWarning />
      )}

      {showTags && tags?.includes("Shared Ownership") && (
        <WarningChip
          label={
            <>
              Shared
              <br />
              Ownership
            </>
          }
          height={"33px"}
          width={"68px"}
        />
      )}
      {showTags && tags?.includes("Retirement") && (
        <WarningChip label={<>Retirement</>} />
      )}
      {showTags && tags?.includes("Auction") && (
        <WarningChip label={<>Auction</>} />
      )}
      {showTags && tags?.includes("Knotweed") && (
        <WarningChip label={<>Knotweed</>} />
      )}
      {showTags && tags?.includes("Park Home") && (
        <WarningChip label={<>Park Home</>} />
      )}
      {showTags && tags?.includes("Short Lease") && (
        <WarningChip label={<>Short Lease</>} />
      )}
    </div>
  );
};

export default PropertyLandworthScore;
