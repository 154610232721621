import { formatMoneyFull, formatMoneyRounded } from "../../services/formatting";

export const getValueChartOptions = (mode, displayUnits) => {
  let appendText;
  if (mode === "ppsf")
    appendText = `/${displayUnits === "ppsm" ? "sqm" : "sqft"}`;
  else if (mode === "price") appendText = "";
  return {
    tooltips: {
      mode: "index",
      intersect: false,
      itemSort: function (a, b) {
        return b.datasetIndex - a.datasetIndex;
      },
      callbacks: {
        title: function (tooltipItem) {
          let date = tooltipItem[0].xLabel.split(" ");
          let displayDate =
            date[0] + " " + date[1] + " " + date[2].slice(0, -1);
          return displayDate;
        },
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex === 2) {
            return ` £${formatMoneyFull(tooltipItem.yLabel, 0)} ${appendText}`;
          } else if (tooltipItem.datasetIndex === 1) {
            return ` £${formatMoneyFull(tooltipItem.yLabel, 0)} ${appendText}`;
          } else {
            return ` £${formatMoneyFull(tooltipItem.yLabel, 0)} ${appendText}`;
          }
        },
      },
    },
    legend: {
      position: "top",
    },
    maintainAspectRatio: true,
    spanGaps: false,
    elements: {
      line: {
        tension: 1,
        cubicInterpolationMode: "monotone",
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "year",
            parser: "timeFormat",
            stepSize: 2,
          },
          display: true,
          scaleLabel: {
            display: false,
            labelString: "Date",
          },
          // ticks: { fontColor: "white" },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 200,
            callback: function (label) {
              if (mode === "ppsf") {
                return "£" + formatMoneyFull(label, 0);
              } else if (mode === "price") {
                return "£" + formatMoneyFull(label / 1000, 0) + "k";
              }
            },
            // fontColor: "white",
          },
          position: "left",
          stacked: false,
          scaleLabel: {
            display: true,
            labelString:
              mode === "ppsf"
                ? displayUnits === "ppsm"
                  ? "Price per SQM"
                  : "Price per SQFT"
                : "Price",
            // fontColor: "white",
          },
        },
      ],
    },
    plugins: {
      filler: {
        propagate: false,
      },
      "samples-filler-analyser": {
        target: "chart-analyser",
      },
    },
    // legend: { labels: { fontColor: "white" } },
  };
};

export const getChangeChartOptions = () => {
  return {
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: function (tooltipItem) {
          let date = tooltipItem[0].xLabel.split(" ");
          let displayDate = date[0] + " " + date[1] + " " + date[2];
          return displayDate;
        },
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex === 0) {
            return ` ${tooltipItem.yLabel}% change in 1yr`;
          } else {
            return ` ${tooltipItem.yLabel}% change in 3yrs`;
          }
        },
      },
    },
    maintainAspectRatio: false,
    spanGaps: false,
    elements: {
      line: {
        tension: 1,
        cubicInterpolationMode: "monotone",
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "year",
            parser: "timeFormat",
            stepSize: 2,
          },
          display: true,
          scaleLabel: {
            display: false,
            labelString: "Date",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            suggestedMin: -20,
            suggestedMax: 20,
            callback: function (label) {
              return label + "%";
            },
          },
          position: "left",
          stacked: false,
          scaleLabel: {
            display: true,
            labelString: "Price Change",
          },
        },
      ],
    },
    plugins: {
      filler: {
        propagate: false,
      },
      "samples-filler-analyser": {
        target: "chart-analyser",
      },
    },
  };
};

export const getPerformanceChartOptions = () => {
  return {
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: function (tooltipItem) {
          let date = tooltipItem[0].xLabel.split(" ");
          let displayDate = date[0] + " " + date[1] + " " + date[2];
          return displayDate;
        },
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex === 0) {
            return ` ${tooltipItem.yLabel}% change at Location`;
          } else if (tooltipItem.datasetIndex === 1) {
            return ` ${tooltipItem.yLabel}% change in London`;
          } else {
            return ` ${tooltipItem.yLabel}% change in UK`;
          }
        },
      },
    },
    maintainAspectRatio: false,
    spanGaps: false,
    elements: {
      line: {
        tension: 1,
        cubicInterpolationMode: "monotone",
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "year",
            parser: "timeFormat",
            stepSize: 2,
          },
          display: true,
          scaleLabel: {
            display: false,
            labelString: "Date",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            suggestedMin: -20,
            suggestedMax: 20,
            callback: function (label) {
              return label + "%";
            },
          },
          position: "left",
          stacked: false,
          scaleLabel: {
            display: true,
            labelString: "Price Change",
          },
        },
      ],
    },
    plugins: {
      filler: {
        propagate: false,
      },
      "samples-filler-analyser": {
        target: "chart-analyser",
      },
    },
  };
};

const quarter_of_the_year = (date) => {
  var month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

export const getSalesChartOptions = () => {
  return {
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: function (tooltipItem) {
          let date = new Date(tooltipItem[0].xLabel);
          let displayDate =
            "Quarter " + quarter_of_the_year(date) + ", " + date.getFullYear();
          return displayDate;
        },
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex === 0) {
            return ` ${tooltipItem.yLabel.toLocaleString()} New Build Sales`;
          } else {
            return ` ${tooltipItem.yLabel.toLocaleString()} Second Hand Sales`;
          }
        },
      },
    },
    legend: {
      position: "top",
    },
    maintainAspectRatio: true,
    spanGaps: false,
    elements: {
      line: {
        tension: 0,
        // cubicInterpolationMode: 'monotone'
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "year",
            parser: "timeFormat",
            stepSize: 2,
          },
          display: true,
          stacked: true,
          scaleLabel: {
            display: false,
            labelString: "Date",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 10,
            callback: function (label) {
              return formatMoneyRounded(label, 0);
            },
          },
          position: "left",
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Total Sales",
          },
        },
      ],
    },
  };
};
