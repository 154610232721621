import { useState, useEffect } from "react";
import useLocalStorage from "@rehooks/local-storage";

function useUnits(initialValue = 1) {
  const [storedSettings, setStoredSettings] = useLocalStorage(
    "userAccountSettings",
    { displayUnits: "ppsf" }
  );
  const [displayUnits, setDisplayUnits] = useState(
    storedSettings.displayUnits || null
  );

  useEffect(() => {
    const savedSettings = JSON.parse(
      localStorage.getItem("userAccountSettings")
    );
    if (savedSettings && savedSettings.displayUnits !== undefined) {
      setDisplayUnits(savedSettings.displayUnits);
    }
  }, [storedSettings]);

  const units = displayUnits === "ppsm" ? "m²" : "ft²";
  const conversionFactor = displayUnits === "ppsm" ? 10.7639 : 1;
  const convertedValuePriceSize = initialValue * conversionFactor;
  const convertedValueSize = Math.round(
    initialValue / conversionFactor
  ).toLocaleString();

  useEffect(() => {
    const updatedSettings = {
      ...storedSettings,
      displayUnits,
    };
    setStoredSettings(updatedSettings);
  }, [displayUnits]);

  return {
    displayUnits,
    setDisplayUnits,
    units,
    convertedValuePriceSize,
    convertedValueSize,
  };
}

export default useUnits;
