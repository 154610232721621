import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";

import { signInFormFields, signInComponents } from "./signInObjects";
import { signUpFormFields, signUpComponents } from "./signUpObjects";
import { isValidDomain } from "./authServices";

import "@aws-amplify/ui-react/styles.css";
import "./authTheme.css";

I18n.putVocabulariesForLanguage("en", {
  "Sign In": "Log In", // Tab header
  "Sign in": "Enter Landworth", // Button label
  "Sign in to your account": "Welcome Back!",
  Username: "Enter your username", // Username label
  Password: "Enter your password", // Password label
  "Forgot your password?": "Reset Password",
});

const formFields = {
  ...signInFormFields,
  ...signUpFormFields,
};

const components = {
  ...signInComponents,
  ...signUpComponents,
};

const NewSignInComponent = (props) => {
  const services = {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      const emailDomain = username.split("@")[1];

      if (!isValidDomain(emailDomain)) {
        window.analytics.track("Blocked Sign-up Attempt", {
          username,
          emailDomain,
        });
        throw new Error("Sign-ups from this email domain are not allowed");
      } else {
        return Auth.signUp({
          name: attributes.name,
          username,
          password,
          attributes,
        });
      }
    },
  };

  return (
    <div
      className="flex-center-center nice-scrollbar"
      style={{
        flex: "1 1 auto",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        borderTop: "1px solid grey",
        justifyContent: "flex-start",
      }}
    >
      <Authenticator
        // hideSignUp={true}
        loginMechanisms={["email"]}
        initialState={"signIn"}
        formFields={formFields}
        components={components}
        services={services}
      >
        {props.children}
      </Authenticator>
    </div>
  );
};
export default NewSignInComponent;
