import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { Container } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: "10px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
  },
}))(Tooltip);

const ComparableGradeInfo = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <HtmlTooltip
        placement="bottom"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        open={open}
        title={
          <div style={{ textAlivgn: "center" }}>
            <Typography
              color="inherit"
              style={{ textAlign: "center", paddingBottom: "4px" }}
            >
              <u>Similarity Grades</u>
            </Typography>
            Grades indicate from A to F how closely a sold property matches the
            one you're valuing, based on location, price, size, and age.
            <br />
            <br />
            Note: Property condition isn't included and should be assessed
            separately.
            {/* <br />
            <ul>
              <li>A: 80%+</li>
              <li>B: 60-80%</li>
              <li>C: 40-60%</li>
              <li>D: 20-40%</li>
              <li>E: 10-20%</li>
              <li>F: -10%</li>
            </ul> */}
          </div>
        }
      >
        <Container
          onClick={handleClick}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {props.children}
        </Container>
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

export default ComparableGradeInfo;
